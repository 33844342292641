"use client";

import { Fragment, useState, useEffect } from "react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { Dialog, Transition } from "@headlessui/react";
import Cookies from "universal-cookie";
import { Button } from "@/components/button";

const cookies = new Cookies();

export function CookieConsent() {
  const [open, setOpen] = useState(false);
  const pathname = usePathname();

  useEffect(() => {
    const hasConsent = cookies.get("lb-cookie-consent");

    if (!hasConsent) {
      setOpen(true);
    }
  }, []);

  useEffect(() => {
    if (pathname.split("/").filter(Boolean).includes("privacy")) {
      setOpen(false);
    }
  }, [pathname]);

  const handleAccept = () => {
    cookies.set("lb-cookie-consent", true, {
      path: "/",
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    });

    setOpen(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <div className="fixed inset-0 bg-black/50" aria-hidden="true" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full pt-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-y-full"
                enterTo="translate-y-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-y-0"
                leaveTo="translate-y-full"
              >
                <Dialog.Panel className="pointer-events-auto absolute bottom-0 w-screen">
                  <div className="h-full overflow-y-scroll bg-white px-5 border-t">
                    <div className="container mx-auto">
                      <div className="flex flex-col min-h-[250px] py-12">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-xl font-bold text-gray-900">
                            Your Privacy
                          </Dialog.Title>
                        </div>
                        <div className="relative flex flex-wrap justify-between mt-3 flex-1">
                          <p className="prose max-w-2xl mb-10">
                            By clicking &ldquo;Accept all cookies&rdquo;, you
                            agree LiveBinders can store cookies on your device
                            and disclose information in accordance with our{" "}
                            <Link href="/privacy#cookie-policy">
                              Cookie Policy
                            </Link>
                            .
                          </p>
                          <Button className="self-end" onClick={handleAccept}>
                            Accept all cookies
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
