"use client";

import { Fragment, useRef } from "react";
import { isEmpty } from "lodash";
import Link from "next/link";
import { Popover, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-thin-svg-icons";
import { cn } from "@/lib/utils";

const HeaderMenuItem = ({ menuItem }) => {
  const triggerRef = useRef();
  const timeOutRef = useRef();

  const handleEnter = (isOpen) => {
    clearTimeout(timeOutRef.current);
    if (!isOpen && triggerRef.current) {
      triggerRef.current.click();
    }
  };

  const handleLeave = (isOpen) => {
    timeOutRef.current = setTimeout(() => {
      if (isOpen && triggerRef.current) {
        triggerRef.current.click();
      }
    }, 0);
  };

  if (menuItem.parentId) return null;

  if (isEmpty(menuItem.childItems.nodes)) {
    return (
      <li>
        <Link href={menuItem.uri} className="flex items-center text-sm">
          {menuItem.label}
        </Link>
      </li>
    );
  }

  return (
    <Popover as="li" className="relative">
      {({ open, close }) => (
        <div
          onMouseEnter={() => handleEnter(open)}
          onMouseLeave={() => handleLeave(open)}
        >
          <Popover.Button
            ref={triggerRef}
            className="flex items-center gap-x-1 text-sm outline-0"
          >
            <Link href={menuItem.uri} onClick={() => close()}>
              {menuItem.label}
            </Link>
            <FontAwesomeIcon
              className="flex-none text-gray-400"
              icon={faChevronDown}
              aria-hidden="true"
            />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              as="ul"
              className="absolute -left-8 top-full z-10 mt-3 w-56 rounded-xl bg-white p-2 shadow-lg ring-1 ring-gray-900/5"
            >
              <Fragment>
                {menuItem.childItems.nodes.map((childMenuItem) => (
                  <li key={childMenuItem.id}>
                    <Link
                      className="block rounded-lg px-3 py-2 text-sm hover:bg-gray-50"
                      href={childMenuItem.uri}
                      onClick={() => close()}
                    >
                      {childMenuItem.label}
                    </Link>
                  </li>
                ))}
              </Fragment>
            </Popover.Panel>
          </Transition>
        </div>
      )}
    </Popover>
  );
};

export const HeaderMenu = ({ className, menuItems }) => {
  if (isEmpty(menuItems)) return null;

  return (
    <Popover.Group>
      <ul className={cn("flex gap-x-8", className)}>
        {menuItems.map((menuItem) => (
          <HeaderMenuItem key={menuItem.id} menuItem={menuItem} />
        ))}
      </ul>
    </Popover.Group>
  );
};
